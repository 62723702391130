import React, { useContext, useState } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import { Link } from 'react-router-dom';


const Navmenu = () => {
    const dbData = useContext(GlobalContext);

    const [openSubMenu, setOpenSubMenu] = useState(false);

    const toggleSubMenu = () => {
        setOpenSubMenu(!openSubMenu);
    }

    //sub menu
    const subitems = dbData.dbServices.map((item, index) => {
        return (
            {
                link: `/${item.name.replace(" ", "-").toLowerCase()}`,
                name: item.name,
            }
        )
    })
    // menu
    const dbMenu = [
        {
            name: `Home`,
            link: `/`,
            child: false,

        },
        {
            name: `About`,
            link: `/about`,
            child: false,
        },
        {
            name: `Services`,
            link: `/services`,
            child: false,
            submenu: [
                ...subitems
            ]
        },
        {
            name: `Gallery`,
            link: `/gallery`,
            child: false,
        },
        {
            name: `Reviews`,
            link: `/reviews`,
            child: false,
        },
        {
            name: `Contact`,
            link: `/contact`,
            child: false,
        },
    ]

    return (
        <ul>
            {
                dbMenu.map((item, index) => {
                    return (
                        <li key={index}>
                            {
                                item.child ?
                                    <Link to="#" className='has-submemu' onClick={toggleSubMenu}>
                                        {item.name}
                                        <i className="fas fa-angle-down ml-2"></i>
                                    </Link>
                                    :
                                    <Link to={item.link}>{item.name}</Link>
                            }
                            {
                                item.child ? (
                                    <ul className={
                                        'submenu' + (openSubMenu ? ' d-block' : '')
                                    }>
                                        {
                                            item.submenu.map((subItem, subIndex) => {
                                                return (
                                                    <li key={subIndex}>
                                                        <Link to={subItem.link}>{subItem.name}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </li>
                    )
                })
            }
        </ul>
    );
}


export default Navmenu;