import React, { useContext } from 'react'
import { GlobalContext } from '../../data/GlobalContext';
import { Col, Row } from 'react-bootstrap';
import FormContent from './FormContent';


const Content = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="contact-section contact-page section-gap-top">
            <div className="container">
                <div className="contact-info">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="illustration-img text-center">
                                <img src={dbData.stock[8]} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                            <div className="contact-info-content">
                                <div className="section-title left-border mb-40">
                                    <span className="title-tag">Get In Touch</span>
                                    <h2 className="title">{dbData.dbSlogan[3].slogan}</h2>
                                </div>
                                <p>
                                    {dbData.dbAbout[1].text}
                                </p>
                                <ul>
                                    <li className="phone">
                                        <Row>
                                            <Col sm={12} >
                                                <a href='#map'>
                                                    <i className="far fa-map-marker-alt" />
                                                    <span>{dbData.dbPrincipal.location[0].address}</span>
                                                </a>
                                            </Col>
                                            {
                                                dbData.dbPrincipal.phones.map((items, index) => {
                                                    return (
                                                        <Col sm={12} key={index}>
                                                            <a href={`tel:1${items.phone}`} >
                                                                <i className="fa fa-phone" />
                                                                <span>{items.phone}</span>
                                                            </a>
                                                        </Col>
                                                    )
                                                })
                                            }
                                            <Col sm={12} >
                                                <i className="far fa-calendar"></i>
                                                <span>{dbData.dbPrincipal.workdays[0].day}</span>
                                                <p>{dbData.dbPrincipal.workHours[0].hour}</p>
                                            </Col>
                                        </Row>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-form grey-bg">
                    <div className="row no-gutters justify-content-center">
                        <div className="col-10">
                            <div className="section-title text-center mb-40">
                                <h2 className="title">Don’t Hesited To Contact Us</h2>
                            </div>
                            <FormContent />
                            {
                                dbData.dbPrincipal.miles !== ' ' ?
                                    <h2 className='pt-5 text-center text-capitalize'>
                                        we cover {dbData.dbPrincipal.miles} miles around {dbData.dbPrincipal.location[0].address}
                                    </h2>
                                    :
                                    <h2 className='pt-5 text-center text-capitalize'>
                                        we cover the entire state of {dbData.dbPrincipal.location[0].address}
                                    </h2>

                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid container-1600">
                <div className="contact-map">
                    <iframe title="map" src={dbData.dbPrincipal.location[0].url} style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
            </div>
        </section>
    );
}


export default Content;