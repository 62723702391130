import React, { Fragment, useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import { Container } from 'react-bootstrap';
import MetaTags from 'react-meta-tags'
import Header from '../layouts/Headertwo'
import Footer from '../layouts/Footer'
import Breadcrumbs from '../layouts/Breadcrumbs'
import CardServices from '../sections/servicetwo/CardServices';



const Servicetwo = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Fragment>
            <MetaTags>
                <title>{dbData.dbPrincipal.name}| Services</title>
                <meta
                    name={dbData.dbSlogan[0].slogan}
                    content={dbData.dbHome[0].text}
                />
                <link rel="icon" type="image/png" sizes="32x32" href={dbData.dbPrincipal.favicon} />
            </MetaTags>
            <Header />
            <Breadcrumbs pagename='Services' bgImage={dbData.stock[2]} />
            <Container style={{ marginBottom: '100px' }}>
                {/* Section Title */}
                <div className="section-title text-center both-border mb-50 mt-50">
                    <span className="title-tag">{dbData.dbSlogan[0].slogan}</span>
                    <h2 className="title text-capitalize">our services</h2>
                </div>
                {/* Services Boxes */}
                <CardServices />
            </Container>
            <Footer />
        </Fragment>
    );
}


export default Servicetwo;