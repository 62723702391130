import React from 'react'

const palteColor = [
    {
        name: 'sherwin-williams',
        url: 'https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf',
        image: 'SW-1.jpg',
    },
    {
        name: 'behr',
        url: 'https://www.behr.com/consumer/colors/paint/explore',
        image: 'behr-1.jpg',
    },
    {
        name: 'benjaminmoore',
        url: 'https://www.benjaminmoore.com/en-us/color-overview/color-palettes',
        image: 'bjm-1.jpg',
    },
]

const imgUrl = require.context('../../../assets/img', true);


const PaletaColor = () => {
    return (
        <div className='container pb-5'>
            <div className='Row d-flex justify-content-cente align-items-center'>
                {
                    palteColor.map((item, index) => {
                        return (
                            <div className='col-md-4 col-sm-12' key={index}>
                                <a href={item.url} target="_blank" className='py-3'>
                                    <img src={imgUrl(`./directorios/${item.image}`)} alt="color" />
                                </a>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PaletaColor